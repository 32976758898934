import {
  LifeCycles,
  ParcelConfig,
  registerApplication,
  start,
} from "single-spa";

registerApplication({
  name: "@kingmakers/betking-layout",
  app: () => System.import<LifeCycles>("@kingmakers/betking-layout"),
  activeWhen: ["/"],
  customProps: {
    betking: System.import<ParcelConfig>("@kingmakers/betking-sportsbook"),
  },
});

start({
  urlRerouteOnly: false,
});
